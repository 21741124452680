import { inject as service } from '@ember/service';
import SecuredRouteMixin from 'mon-pix/mixins/secured-route-mixin';
import Route from '@ember/routing/route';

export default class ExternalLogin extends Route.extend(SecuredRouteMixin) {
  @service location;
  @service session;

  beforeModel(transition) {
    let dest = 'https://nsix.fr/';
    const queryParams = transition.to ? transition.to.queryParams : transition.queryParams;
    if(queryParams && queryParams.dest) {
      dest = queryParams.dest;
    }
    const isUserLoaded = !!this.currentUser.user;
    const isAuthenticated = this.session.get('isAuthenticated');
    if (!isAuthenticated || !isUserLoaded) {
      sessionStorage.setItem('externalUrl', dest);
      this.router.transitionTo(this.authenticationRoute);
    } else {
      this.location.replace(dest);
    }
  }
}
