import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-implicit-this no-action no-curly-component-invocation no-invalid-interactive }}\n<div class=\"pix-toggle\">\n  <span\n    class=\"{{firstButtonClass}}\"\n    onToggle={{action \"onToggle\" \"firstButtonClass\" on=\"click\"}}\n  >{{valueFirstLabel}}</span>\n  <span\n    class=\"{{secondButtonClass}}\"\n    onToggle={{action \"onToggle\" \"secondButtonClass\" on=\"click\"}}\n  >{{valueSecondLabel}}</span>\n</div>\n{{yield}}", {"contents":"{{! template-lint-disable no-implicit-this no-action no-curly-component-invocation no-invalid-interactive }}\n<div class=\"pix-toggle\">\n  <span\n    class=\"{{firstButtonClass}}\"\n    onToggle={{action \"onToggle\" \"firstButtonClass\" on=\"click\"}}\n  >{{valueFirstLabel}}</span>\n  <span\n    class=\"{{secondButtonClass}}\"\n    onToggle={{action \"onToggle\" \"secondButtonClass\" on=\"click\"}}\n  >{{valueSecondLabel}}</span>\n</div>\n{{yield}}","moduleName":"mon-pix/components/pix-toggle.hbs","parseOptions":{"srcName":"mon-pix/components/pix-toggle.hbs"}});
/* eslint ember/no-actions-hash: 0 */
/* eslint ember/no-classic-classes: 0 */
/* eslint ember/no-classic-components: 0 */
/* eslint ember/require-tagless-components: 0 */

import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  valueFirstLabel: '',
  valueSecondLabel: '',
  isFirstOn: true,

  firstButtonClass: computed('isFirstOn', function () {
    return this.isFirstOn ? 'pix-toggle__on' : 'pix-toggle__off';
  }),

  secondButtonClass: computed('isFirstOn', function () {
    return this.isFirstOn ? 'pix-toggle__off' : 'pix-toggle__on';
  }),

  click: function (e) {
    if (e.target.className === 'pix-toggle__off') {
      this.toggleProperty('isFirstOn');
    }

    this.onToggle(this.isFirstOn);
  },

  actions: {
    onToggle: () => {},
  },
});
