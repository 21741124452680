import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { v4 } from 'uuid';
import get from 'lodash/get';
import ENV from 'mon-pix/config/environment';

const { host, clientId, authEndpoint, cookieDomain } = ENV.neo;

export default class LoginNEORoute extends Route {
  @service router;

  beforeModel() {
    this.router.replaceWith('authentication.login-oidc', 'neo');
  }
}
