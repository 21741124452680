import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a class=\"training-card\" href={{@training.link}} target=\"_blank\" rel=\"noopener noreferrer\">\n  <picture class=\"training-card__visual\">\n    <img\n      src=\"/images/logo/logo-ministere-education-nationale-et-jeunesse.svg\"\n      alt=\"{{t \"common.french-education-ministry\"}}\"\n    />\n  </picture>\n  <div class=\"training-card__content\">\n    <h3 class=\"training-card__title\">{{@training.title}}</h3>\n    <ul class=\"training-card__infos\">\n      <li class=\"training-card__type\">{{@training.type}}</li>\n      <li class=\"training-card__duration\">\n        <FaIcon @icon=\"clock\" @prefix=\"far\" aria-hidden=\"true\" />\n        <time>{{this.durationFormatted}}</time>\n      </li>\n    </ul>\n  </div>\n</a>", {"contents":"<a class=\"training-card\" href={{@training.link}} target=\"_blank\" rel=\"noopener noreferrer\">\n  <picture class=\"training-card__visual\">\n    <img\n      src=\"/images/logo/logo-ministere-education-nationale-et-jeunesse.svg\"\n      alt=\"{{t \"common.french-education-ministry\"}}\"\n    />\n  </picture>\n  <div class=\"training-card__content\">\n    <h3 class=\"training-card__title\">{{@training.title}}</h3>\n    <ul class=\"training-card__infos\">\n      <li class=\"training-card__type\">{{@training.type}}</li>\n      <li class=\"training-card__duration\">\n        <FaIcon @icon=\"clock\" @prefix=\"far\" aria-hidden=\"true\" />\n        <time>{{this.durationFormatted}}</time>\n      </li>\n    </ul>\n  </div>\n</a>","moduleName":"mon-pix/components/training/card.hbs","parseOptions":{"srcName":"mon-pix/components/training/card.hbs"}});
import Component from '@glimmer/component';

export default class Card extends Component {
  get durationFormatted() {
    const hours = this.args.training.duration.hours + 'h';
    return hours;
  }
}
