import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class={{@class}} ...attributes>\n  {{this.html}}\n</div>", {"contents":"<div class={{@class}} ...attributes>\n  {{this.html}}\n</div>","moduleName":"mon-pix/components/markdown-to-html-unsafe.hbs","parseOptions":{"srcName":"mon-pix/components/markdown-to-html-unsafe.hbs"}});
import Component from '@glimmer/component';
import showdown from 'showdown';
import { htmlSafe } from '@ember/string';
import ENV from 'mon-pix/config/environment';

export default class MarkdownToHtmlUnsafe extends Component {
  get options() {
    return {
      ...ENV.showdown,
      extensions: this.args.extensions ? this.args.extensions.split(' ') : [],
    };
  }

  get html() {
    const converter = new showdown.Converter(this.options);
    const unsafeHtml = converter.makeHtml(this.args.markdown);
    return htmlSafe(unsafeHtml);
  }
}
