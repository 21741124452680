import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
import ENV from 'mon-pix/config/environment';

const { cookieDomain } = ENV.neo;

export default Mixin.create({
  router: service(),
  session: service('session'),
  currentUser: service(),
  authenticationRoute: 'authentication.login',

  beforeModel(transition) {
    const expirationTime = 14 * 24 * 60 * 60; // 14 days
    this.set('session.store.cookieExpirationTime', expirationTime);
    this.set('session.store.cookieDomain', cookieDomain);

    const isUserLoaded = !!this.currentUser.user;
    const isAuthenticated = this.session.get('isAuthenticated');
    if (!isAuthenticated || !isUserLoaded) {
      this.session.set('attemptedTransition', transition);
      this.router.transitionTo(this.authenticationRoute);
    } else if (this.currentUser.user.mustValidateTermsOfService) {
      this.session.set('attemptedTransition', transition);
      this.router.transitionTo('terms-of-service');
    } else {
      const url = sessionStorage.getItem('externalUrl');
      if(url && url.length) {
        sessionStorage.removeItem('externalUrl')
        window.location.replace(url);
      }
      // DEBUG: temporary redirect to home until actual activation
      // window.location.replace('https://nsix.fr');
      return this._super(...arguments);
    }
  },
});
