import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  class=\"action-chip {{if @isCompleted \"action-chip--selected\" \"\"}}\"\n  aria-label={{@title}}\n  title={{@title}}\n  type=\"button\"\n  {{on \"click\" this.triggerAction}}\n  disabled={{this.isTriggering}}\n  aria-disabled={{this.isTriggering}}\n>\n  <FaIcon class=\"action-chip__icon\" @prefix={{if @isCompleted \"fas\" \"far\"}} @icon=\"{{@icon}}\" />\n</button>", {"contents":"<button\n  class=\"action-chip {{if @isCompleted \"action-chip--selected\" \"\"}}\"\n  aria-label={{@title}}\n  title={{@title}}\n  type=\"button\"\n  {{on \"click\" this.triggerAction}}\n  disabled={{this.isTriggering}}\n  aria-disabled={{this.isTriggering}}\n>\n  <FaIcon class=\"action-chip__icon\" @prefix={{if @isCompleted \"fas\" \"far\"}} @icon=\"{{@icon}}\" />\n</button>","moduleName":"mon-pix/components/action-chip.hbs","parseOptions":{"srcName":"mon-pix/components/action-chip.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
export default class ActionChip extends Component {
  @tracked
  isTriggering = false;

  @action
  async triggerAction() {
    if (this.args.triggerAction && !this.isTriggering) {
      this.isTriggering = true;
      await this.args.triggerAction();
    }
    this.isTriggering = false;
  }
}
