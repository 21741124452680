import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"qcm-proposals\">\n  {{#each this.labeledCheckboxes as |labeledCheckbox index|}}\n    <p class=\"proposal-paragraph\">\n\n      <Input\n        @type=\"checkbox\"\n        id=\"checkbox_{{inc index}}\"\n        @checked={{get labeledCheckbox 1}}\n        data-test=\"challenge-response-proposal-selector\"\n        name=\"{{inc index}}\"\n        {{on \"click\" (fn this.checkboxClicked (inc index))}}\n        disabled={{@isAnswerFieldDisabled}}\n      />\n\n      <label for=\"checkbox_{{inc index}}\" class=\"label-checkbox-proposal\">\n        <MarkdownToHtml @class=\"proposal-text\" @markdown={{get labeledCheckbox 0}} />\n      </label>\n    </p>\n  {{/each}}\n</div>", {"contents":"<div class=\"qcm-proposals\">\n  {{#each this.labeledCheckboxes as |labeledCheckbox index|}}\n    <p class=\"proposal-paragraph\">\n\n      <Input\n        @type=\"checkbox\"\n        id=\"checkbox_{{inc index}}\"\n        @checked={{get labeledCheckbox 1}}\n        data-test=\"challenge-response-proposal-selector\"\n        name=\"{{inc index}}\"\n        {{on \"click\" (fn this.checkboxClicked (inc index))}}\n        disabled={{@isAnswerFieldDisabled}}\n      />\n\n      <label for=\"checkbox_{{inc index}}\" class=\"label-checkbox-proposal\">\n        <MarkdownToHtml @class=\"proposal-text\" @markdown={{get labeledCheckbox 0}} />\n      </label>\n    </p>\n  {{/each}}\n</div>","moduleName":"mon-pix/components/qcm-proposals.hbs","parseOptions":{"srcName":"mon-pix/components/qcm-proposals.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import createProposalAnswerTuples from 'mon-pix/utils/labeled-checkboxes';
import proposalsAsArray from 'mon-pix/utils/proposals-as-array';
import valueAsArrayOfBoolean from 'mon-pix/utils/value-as-array-of-boolean';

export default class QcmProposals extends Component {
  get labeledCheckboxes() {
    const arrayOfProposals = proposalsAsArray(this.args.proposals);
    const arrayOfBoolean = valueAsArrayOfBoolean(this.args.answerValue);

    return createProposalAnswerTuples(arrayOfProposals, arrayOfBoolean);
  }

  @action
  checkboxClicked(checkboxName) {
    this.args.answerChanged(checkboxName);
  }
}
