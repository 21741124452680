import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasLearningMoreItems}}\n  <div class=\"learning-more-panel\">\n    <div class=\"learning-more-panel__container\">\n      <h3 class=\"learning-more-panel__hint-title\"><span>{{t \"pages.learning-more.title\"}}</span></h3>\n      <div class=\"learning-more-panel__list-container\">\n        {{#each @learningMoreTutorials as |learningMoreTutorial|}}\n          <Tutorials::Card @tutorial={{learningMoreTutorial}} />\n        {{/each}}\n        <div class=\"learning-more-panel__tutorial-info\">{{t \"pages.learning-more.info\"}}</div>\n      </div>\n    </div>\n  </div>\n{{/if}}", {"contents":"{{#if this.hasLearningMoreItems}}\n  <div class=\"learning-more-panel\">\n    <div class=\"learning-more-panel__container\">\n      <h3 class=\"learning-more-panel__hint-title\"><span>{{t \"pages.learning-more.title\"}}</span></h3>\n      <div class=\"learning-more-panel__list-container\">\n        {{#each @learningMoreTutorials as |learningMoreTutorial|}}\n          <Tutorials::Card @tutorial={{learningMoreTutorial}} />\n        {{/each}}\n        <div class=\"learning-more-panel__tutorial-info\">{{t \"pages.learning-more.info\"}}</div>\n      </div>\n    </div>\n  </div>\n{{/if}}","moduleName":"mon-pix/components/learning-more-panel.hbs","parseOptions":{"srcName":"mon-pix/components/learning-more-panel.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class LearningMorePanel extends Component {
  @service featureToggles;

  get hasLearningMoreItems() {
    const learningMoreTutorials = this.args.learningMoreTutorials || [];
    return learningMoreTutorials.length > 0;
  }
}
